import api from "../_axios";

const getCities = () => api.get("https://backend.downtown.com/api/v1/cities");
const getAreas = (cityid: number) =>
  api.get(`https://backend.downtown.com/api/v1/areas/${cityid}`);
const getLanguages = () =>
  api.get("https://backend.downtown.com/api/v1/languages");
const getEducationTypes = (school_year_id: any) =>
  api.get(
    `https://backend.downtown.com/api/v1/education-types?school_year_id=${school_year_id}`
  );
const getSchoolYears = (teacherId?: number) =>
  api.get(
    `https://backend.downtown.com/api/v1/school-years?teacher_id=${teacherId ? teacherId : ""
    }`
  );
const getSchoolTypes = () =>
  api.get("https://backend.downtown.com/api/v1/school-types");

const GENERALAPI = {
  getCities,
  getAreas,
  getLanguages,
  getEducationTypes,
  getSchoolYears,
  getSchoolTypes,
};
export default GENERALAPI;
